export const SortOptionList = locale => {
  return [
    {
      label: locale['Due Date - Asc'],
      key: 'due-date-asc',
      value: 0,
      flag: 0,
    },
    {
      label: locale['Created Date - Asc'],
      key: 'created-date-asc',
      value: 1,
      flag: 1,
    },
    {
      // TODO: is not available in focus list
      label: locale['Last Update Date'],
      key: 'last-updated-date',
      value: 2,
      flag: 2,
    },
    {
      label: locale['Due Date - Desc'],
      key: 'due-date-dsc',
      value: 3,
      flag: 3,
    },
    {
      label: locale['Created Date - Desc'],
      key: 'created-date-dsc',
      value: 4,
      flag: 4,
    },
  ]
}

export const defaultSortOption = (locale, queue) => {
  let index
  if ('MY_FOCUS' === queue.queue_id) {
    index = SortOptionList(locale).findIndex(
      item => item.key === 'created-date-dsc'
    )
  } else {
    index = SortOptionList(locale).findIndex(
      item => item.key === 'last-updated-date'
    )
    if (index === -1)
      index = SortOptionList(locale).findIndex(
        item => item.key === 'created-date-dsc'
      )
  }
  return index >= 0 ? SortOptionList(locale)[index] : SortOptionList()[0]
}
