/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import {
	Box,
	VStack,
	Flex,
	HStack,
	Icon,
	IconButton,
	Button,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Divider,
	List,
	ListIcon,
	ListItem,
	Text,
	Skeleton,
} from '@chakra-ui/react'
import { useLoadStatusAndActivityType } from '../../../hooks/activity.hooks'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import { groupBy } from 'lodash'
import { FiArrowLeftCircle } from 'react-icons/fi'
import { ACTION_PANEL_VIEW } from '../action-panel'
import { MdArrowDropDown, MdCheckCircle } from 'react-icons/md'
import { BsCircle } from 'react-icons/bs'
import { sectionDetailedTrack } from '../../repository/repo.utils'
import { GA_CATEGORY_FILES } from './../../repository/repo.utils'

const StatusActivityTypeView = ({
	setpanelView,
	selectedQueue,
	selectedFilter,
	selectedStatus,
	setSelectedStatus,
	selectedSubStatus,
	setSelectedSubStatus,
	statusIndex,
	setStatusIndex,
}) => {
	const { mutate, isLoading, isError } = useLoadStatusAndActivityType()
	const [data, setdata] = useState({})
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { organization_flag_enable_tag } = authData

	useEffect(() => {
		mutate(
			{ flag: 0 },
			{
				onSuccess: async data => {
					let groupedData
					if (organization_flag_enable_tag === 1) {
						groupedData = groupBy(data, 'tag_type_id')
					} else {
						groupedData = groupBy(data, 'activity_type_category_id')
					}
					setdata(groupedData)
				},
			}
		)
	}, [])
	//organization_flag_enable_tag
	return (
		<>
			<HStack w='100%' bg='secondary' spacing={2} p={2}>
				<Icon
					className={`status-activity-view-back-icon`}
					as={FiArrowLeftCircle}
					cursor='pointer'
					color={localStorage.getItem('color')}
					w={6}
					h={6}
					onClick={() => {
						sectionDetailedTrack({
							category: GA_CATEGORY_FILES,
							action: 'Activity Status View',
							label: 'Go Back',
						})
						setStatusIndex({ cat: 0, sub_cat: null })
						setpanelView(ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW)
					}}
				/>
				<Text>
					<Text as='span' fontWeight='900'>
						{selectedQueue.queue_name || selectedFilter.activity_type_name}
					</Text>
				</Text>
			</HStack>
			<Accordion
				allowToggle={true}
				defaultIndex={[statusIndex.cat]}
				w='full'
				maxH='calc(100% - 100px)'
				overflowY='scroll'
				px={1}
				onChange={e => {
					setStatusIndex({ cat: e, sub_cat: 0 })
				}}
			>
				{!isLoading && !Object.keys(data).length ? (
					<Flex alignItems='center' justifyContent='center'>
						<Text>{locale['No content found!']}</Text>
					</Flex>
				) : isLoading ? (
					[1, 2, 3, 4, 5].map(i => {
						return (
							<Skeleton
								boxShadow='md'
								borderRadius='md'
								key={i}
								mb={2}
								w='full'
								h='50px'
							/>
						)
					})
				) : (
					<AccordionItem
						index={0}
						border='none'
						boxShadow='md'
						borderRadius='md'
						mb={2}
					>
						<AccordionButton
							_expanded={{
								bg: 'secondary',
								color: 'brand.900',
								borderRadius: 'md',
							}}
							_focus={{
								border: 'none',
							}}
						>
							<Box
								flex='1'
								textAlign='left'
								cursor='pointer'
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FILES,
										action: 'Activity Status View',
										label: 'Show All',
									})
									setSelectedStatus({})
									setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
								}}
							>
								<Text size='sm' fontWeight='500'>
									{locale['All']}
								</Text>
							</Box>
						</AccordionButton>
					</AccordionItem>
				)}
				{!!data &&
					Object.keys(data).map((activity_type_category_id, i) => {
						return (
							<AccordionItem
								border='none'
								boxShadow='md'
								borderRadius='md'
								mb={2}
								key={i}
								index={i + 1}
							>
								<AccordionButton
									_expanded={{
										bg: 'secondary',
										color: 'brand.900',
										borderRadius: 'md',
									}}
									_focus={{
										border: 'none',
									}}
								>
									<Box
										flex='1'
										textAlign='left'
										cursor='pointer'
										onClick={e => {
											sectionDetailedTrack({
												category: GA_CATEGORY_FILES,
												action: 'Activity Status View',
												label: 'Switch To List View',
											})
											setSelectedStatus({
												...data[activity_type_category_id][0],
												activity_status_id: 0,
												activity_type_id: 0,
												hideStatus: true,
												hideCategory: true,
												cat_index: i + 1,
												sub_cat_index: null,
												tag_id: 0,
											})
											setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
											e.stopPropagation()
										}}
									>
										<Text size='sm' fontWeight='500'>
											{organization_flag_enable_tag === 1
												? data[activity_type_category_id][0].tag_type_name
													? data[
															activity_type_category_id
													  ][0].tag_type_name.endsWith('y')
														? `My ` +
														  data[
																activity_type_category_id
														  ][0].tag_type_name.slice(0, -1) +
														  'ies'
														: `My ` +
														  data[activity_type_category_id][0].tag_type_name +
														  's'
													: locale['Others']
												: `My ` +
												  data[activity_type_category_id][0]
														.activity_type_category_name +
												  `s`}
										</Text>
									</Box>
									<AccordionIcon as={MdArrowDropDown} />
								</AccordionButton>
								<AccordionPanel pb={4} maxH='250px' overflowY='auto'>
									<Accordion
										allowToggle={true}
										defaultIndex={[statusIndex.sub_cat]}
									>
										{Object.keys(
											groupBy(
												data[activity_type_category_id],
												'activity_type_name'
											) || []
										).map((activity_type_name, j) => {
											return (
												<AccordionItem
													border='none'
													boxShadow='md'
													borderRadius='md'
													mb={2}
													key={Math.random().toString()}
													index={j}
												>
													<AccordionButton
														_expanded={{
															bg: 'secondary',
															color: 'brand.900',
															borderRadius: 'md',
														}}
														_focus={{
															border: 'none',
														}}
													>
														<Box
															flex='1'
															textAlign='left'
															cursor='pointer'
															onClick={e => {
																sectionDetailedTrack({
																	category: GA_CATEGORY_FILES,
																	action: 'Activity Status View',
																	label: 'Switch To List View',
																})
																setSelectedStatus({
																	...groupBy(
																		data[activity_type_category_id],
																		'activity_type_name'
																	)[activity_type_name][0],
																	activity_status_id: 0,
																	hideStatus: true,
																	cat_index: i + 1,
																	sub_cat_index: j,
																})
																setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
																e.stopPropagation()
															}}
														>
															<Text size='sm' fontWeight='500'>
																{activity_type_name}
															</Text>
														</Box>
														<AccordionIcon as={MdArrowDropDown} />
													</AccordionButton>
													<AccordionPanel pb={4} maxH='250px' overflowY='auto'>
														<List spacing={3}>
															{groupBy(
																data[activity_type_category_id],
																'activity_type_name'
															)[activity_type_name].map(
																(activity_status_name, k) => {
																	return (
																		<div key={`${activity_status_name}-${k}`}>
																			{activity_status_name?.activity_status_name && (
																				<ListItem
																					cursor='pointer'
																					py={1}
																					onClick={e => {
																						sectionDetailedTrack({
																							category: GA_CATEGORY_FILES,
																							action: 'Activity Status View',
																							label: 'Activity List View',
																						})
																						setSelectedStatus({
																							...activity_status_name,
																							cat_index: i + 1,
																							sub_cat_index: j,
																						})
																						setpanelView(
																							ACTION_PANEL_VIEW.LIST_VIEW
																						)
																						e.stopPropagation()
																					}}
																					display='flex'
																				>
																					<ListIcon
																						as={BsCircle}
																						color={localStorage.getItem(
																							'color'
																						)}
																						w={6}
																						h={6}
																					/>
																					<Text fontSize='sm'>
																						{
																							activity_status_name?.activity_status_name
																						}
																					</Text>
																				</ListItem>
																			)}
																			{data[activity_type_category_id].length >
																				1 && <Divider mt={1} />}
																		</div>
																	)
																}
															)}
														</List>
													</AccordionPanel>
												</AccordionItem>
											)
										})}
									</Accordion>
								</AccordionPanel>
							</AccordionItem>
						)
					})}
			</Accordion>
		</>
	)
}

export default StatusActivityTypeView
