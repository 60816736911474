/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, Icon, Tag, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { GoMention } from 'react-icons/go'
import { ImStack } from 'react-icons/im'
import { MdCenterFocusWeak, MdTimelapse, MdUpdate } from 'react-icons/md'
import Card from '../../../components/card'
import QueueType from '../../../components/queue-type'
import WorkflowTypeFilter from '../../../components/workflow-type-filter'
import { useSharedContext } from '../../../context/shared.context'
import { useLoadActivityCounts } from '../../../hooks/shared.hooks'
import { sharedConst } from '../../../utils/action.constant'
import { ACTION_PANEL_VIEW } from '../action-panel'
import { toSnakeCase } from '../../../utils/common.util'
// import {
// 	dataLayerTagManager,
// 	eventLabelActionTrack,
// } from './../../repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'

const QueueSetup = ({
	selectedFilter,
	setSelectedFilter,
	selectedQueue,
	setSelectedQueue,
	setpanelView,
	setSelectedStatus,
	selectedIndex,
	setselectedIndex,
	...props
}) => {
	const { mutate: statsMutate, data } = useLoadActivityCounts()
	// const { dispatch } = useSharedContext();
	const { dispatch } = useSharedContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const staticQueueList = [
		{
			queue_name: locale['Focus List'],
			queue_id: 'MY_FOCUS',
			isStatic: true,
			icon: MdCenterFocusWeak,
			color: '#F7B017',
			flag: 1,
			isStatusFilter: 0,
			key: 'count_focus',
			title: locale['List that needs Immediate Focus'],
		},
		{
			queue_name: locale['My updates'],
			queue_id: 'UNREAD',
			isStatic: true,
			icon: MdUpdate,
			color: 'brand.800',
			flag: 11,
			isStatusFilter: 0,
			key: 'count_unread',
			title: locale['My Updates'],
		},
		{
			queue_name: locale['Mentions'],
			queue_id: 'MENTIONS',
			isStatic: true,
			icon: GoMention,
			color: 'orange.400',
			flag: 7,
			isStatusFilter: 0,
			key: 'count_mention',
			title: locale['Mentioned'],
		},
		{
			queue_name: locale['Delayed'], /// past due
			queue_id: 'DELAYED', /// past due
			isStatic: true,
			icon: MdTimelapse,
			color: 'red.500',
			flag: 6,
			isStatusFilter: 0,
			key: 'count_pastdue',
			title: locale['Your delayed list'],
		},
		{
			queue_name: locale['My Files'],
			queue_id: 'MY_QUEUE',
			isStatic: true,
			icon: ImStack,
			color: 'brand.800',
			flag: 0,
			isStatusFilter: 1,
			title: locale['List of My files'],
		},
	]

	useEffect(() => {
		setSelectedStatus({})
		statsMutate({ flag: 0 })
	}, [])

	return (
		<>
			{(staticQueueList || []).map(queue => {
				let hasCountKey = queue.hasOwnProperty('key')
				if (!!hasCountKey && data && !data[queue.key]) {
					return
				}
				return (
					<Card
						className={`queue-setup-card-${toSnakeCase(queue.queue_name)}`}
						minH='40px'
						// my={2}
						display='flex'
						justifyContent='space-around'
						boxShadow='md'
						cursor='pointer'
						onClick={() => {
							// eventLabelActionTrack({
							// 	categoryId: 0,
							// 	action: queue.queue_name,
							// 	label: `Select ${queue.queue_name} for listing activities`,
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Home',
							// 	buttonName: queue.queue_name,
							// })
							dispatch({
								type: sharedConst.SET_SELECTED_QUEUE,
								queue,
								isFirst: false,
							})
							dispatch({
								type: sharedConst.SET_SELECTED_FILTER,
								filter: {},
							})
							if (!!queue.isStatusFilter) {
								dispatch({
									type: sharedConst.SET_ACTION_PANEL_VIEW,
									view: ACTION_PANEL_VIEW.STATUS_TYPE_VIEW,
								})
							} else {
								dispatch({
									type: sharedConst.SET_ACTION_PANEL_VIEW,
									view: ACTION_PANEL_VIEW.LIST_VIEW,
								})
							}
						}}
						key={queue.queue_id}
						border={selectedQueue.queue_id === queue.queue_id ? '1px' : 'none'}
						borderColor='brand.800'
					>
						<Icon
							color={queue.color}
							as={queue.icon}
							w={6}
							h={6}
							className={`queue-setup-icon-${toSnakeCase(queue.queue_name)}`}
						/>
						<Text
							size='md'
							pl={2}
							flex={1}
							className={`queue-setup-text-${toSnakeCase(queue.queue_name)}`}
						>
							{queue.queue_name}
						</Text>
						{!!hasCountKey && (
							<Tag
								className={`queue-setup-tag-${toSnakeCase(queue.queue_name)}`}
								size='md'
								borderRadius='full'
								variant='solid'
								bg={queue.color}
							>
								{!!data ? data[queue.key] || 0 : 0}
							</Tag>
						)}
					</Card>
				)
			})}
			<Accordion
				allowToggle={true}
				w='full'
				className={`queue-setup-accordion}`}
				// defaultIndex={[selectedIndex]}
				// onChange={(expandedIndex) => { setselectedIndex(expandedIndex) }}
			>
				<QueueType
					onQueueSelection={d => {
						dispatch({
							type: sharedConst.SET_SELECTED_QUEUE,
							queue: d,
							isFirst: false,
						})
						dispatch({
							type: sharedConst.SET_ACTION_PANEL_VIEW,
							view: ACTION_PANEL_VIEW.LIST_VIEW,
						})
					}}
					onFilterChange={d => {
						dispatch({
							type: sharedConst.SET_SELECTED_FILTER,
							filter: d,
						})
						dispatch({
							type: sharedConst.SET_ACTION_PANEL_VIEW,
							view: ACTION_PANEL_VIEW.LIST_VIEW,
						})
					}}
					selectedQueue={selectedQueue}
				/>
				<WorkflowTypeFilter
					selectedFilter={selectedFilter}
					onQueueSelection={d => {
						setSelectedQueue(d)
						setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)

						dispatch({
							type: sharedConst.SET_SELECTED_QUEUE,
							queue: d,
							isFirst: false,
						})
						dispatch({
							type: sharedConst.SET_ACTION_PANEL_VIEW,
							view: ACTION_PANEL_VIEW.LIST_VIEW,
						})
					}}
					onFilterChange={d => {
						setSelectedFilter(d)
						setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
						dispatch({
							type: sharedConst.SET_SELECTED_FILTER,
							filter: d,
						})
						dispatch({
							type: sharedConst.SET_ACTION_PANEL_VIEW,
							view: ACTION_PANEL_VIEW.LIST_VIEW,
						})
					}}
				/>
			</Accordion>
		</>
	)
}

export default QueueSetup
