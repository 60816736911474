/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Icon,
	VStack,
	IconButton,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	InputLeftElement,
	Text,
	Switch,
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { BsPlus } from 'react-icons/bs'
import { MdClose, MdRefresh, MdSearch, MdSort } from 'react-icons/md'
import { useSharedContext } from '../../context/shared.context'
import ActivityListView from './activity-listing-view'
import QueueSetup from './queue-setup'
import StatusActivityTypeView from './status-activity-type-view'
import CustomMenu from '../../components/menu'
import {
	defaultSortOption,
	SortOptionList,
} from '../../../src/containers/action-panel/activity-listing-view/sort.option'
import { getCurrentDateISTWidgetDash } from '../../utils/date.utils'
import { getCurrentDateUTC } from '../../utils/common.util'
import {
	useLoadActivityListV1,
	useLoadActivityListV2,
	useLoadFilterActivityList,
	useLoadFocusActivityList,
	useLoadChildOrderActivity,
	useLoadUnassignedQueueList,
} from '../../hooks/activity.hooks'
import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import { sharedConst } from '../../utils/action.constant'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	sectionDetailedTrack,
// } from '../repository/repo.utils'
import { GA_CATEGORY_FILES } from './../repository/repo.utils'

export const ACTION_PANEL_VIEW = {
	LIST_VIEW: 'LIST_VIEW',
	STATUS_TYPE_VIEW: 'STATUS_TYPE_VIEW',
	QUEUE_SETUP_VIEW: 'QUEUE_SETUP_VIEW',
}

const ActionPanel = ({
	openFormView,
	openActivityView,
	setToggleButton,
	toggleButton,
}) => {
	const {
		state: { filter, queue, view, isFirst, isReloadList },
		dispatch,
	} = useSharedContext()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const [selectedFilter, setSelectedFilter] = useState({})
	const [selectedQueue, setSelectedQueue] = useState({})
	const [selectedStatus, setSelectedStatus] = useState({})
	const [statusIndex, setStatusIndex] = useState({ cat: 0, sub_cat: null })
	const [searchQuery, setSearchQuery] = useState('')

	const [selectedSort, setselectedSort] = useState({})
	const [data, setdata] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const [isRefresh, setIsRefresh] = useState(false)
	const [isBtnLoading, setIsBtnLoading] = useState(false)
	const [activity, setActivity] = useState({})
	const [isChildActive, setChildActive] = useState(false)
	const [child_title, setChildOrderTitle] = useState('')

	const { mutate: activityListV1Mutate } = useLoadActivityListV1()
	const { mutate: activityListV2Mutate } = useLoadActivityListV2()
	const { mutate: UnassignedQueueList } = useLoadUnassignedQueueList()
	const { mutate: focusListMutate } = useLoadFocusActivityList()
	const { mutate: filterListMutate } = useLoadFilterActivityList()
	const { mutate: activityChildOrderListMutate } = useLoadChildOrderActivity()

	const [panelView, setpanelView] = useState(ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW)

	const { organization_flag_enable_tag, organization_flag_enable_collapsible } =
		authData

	useEffect(() => {
		setSelectedFilter(filter || {})
		setSelectedQueue(queue || {})
		setselectedSort(defaultSortOption(locale, queue))
		setpanelView(view || ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW)
		if (!!isFirst) {
			openActivityView()
		}
	}, [filter, queue, view, isFirst])

	useEffect(() => {
		setSearchQuery('')
		loadActivity({
			searchQuery,
			selectedSort,
			selectedStatus,
		})
	}, [selectedFilter, selectedQueue, selectedStatus])

	const onSuccess = (res, isChild = false, pagestart = 0) => {
		setIsLoading(false)
		setIsBtnLoading(false)
		// if (
		//   !!searchQuery ||
		//   !data ||
		//   !Object.keys(data).length ||
		//   (isChild && pagestart === 0) ||
		//   (isRefresh && pagestart === 0)
		// ) {
		if (pagestart === 0) {
			setdata(res)
		} else if (!!data && !!res) {
			const { list, ...rest } = res
			setdata({
				...rest,
				list: [...data.list, ...list],
			})
		}
	}

	const onError = err => {
		setIsLoading(false)
		setIsBtnLoading(false)
	}
	const loadActivity = useCallback(
		({
			start = 0,
			searchQuery = '',
			isRefresh = !!selectedQueue.isRefresh ? selectedQueue.isRefresh : false,
			selectedSort = SortOptionList(locale)[0],
			selectedStatus,
		}) => {
			setIsBtnLoading(true)
			setIsRefresh(false)
			if (!start) {
				setIsLoading(true)
			}
			if (isRefresh) {
				setIsRefresh(true)
			}
			if (Object.keys(selectedFilter).length > 0) {
				filterListMutate(
					{
						isElasticSearchEnabled:
							authData.organization_flag_elasticsearch_enabled,
						workforce_id: 0,
						search_string: searchQuery || '',
						flag_status: 0,
						flag_participating: 2,
						device_os_id: 5,
						page_limit: 50,
						page_start: start,
						activity_type_category_id: selectedFilter.activity_type_category_id, // TODO: send the category id from listing process type
						activity_type_id: selectedFilter.activity_type_id,
						tag_id: 0,
						tag_type_id: 0,
						activity_status_type_id: 0,
						sort_flag: selectedSort.value || 0,
					},
					{
						onSuccess: res => {
							if (res) {
								onSuccess(res, false, start)
							}
						},
						onError,
					}
				)
			} else if (Object.keys(selectedQueue).length > 0) {
				if (!selectedQueue.isStatic) {
					if (
						!!selectedQueue.queue_type_id &&
						selectedQueue.queue_type_id === 4
					) {
						UnassignedQueueList(
							{
								page_start: start,
								page_limit: 30,
								sort_flag: selectedSort.value || 0,
								flag: 0,
								queue_id: selectedQueue.queue_id,
								queue_type_id: selectedQueue.queue_type_id,
								is_active: 0,
								is_due: 0,
								current_datetime: getCurrentDateISTWidgetDash(),
								is_unread: 0,
								is_search: !!searchQuery ? 1 : 0,
								search_string: searchQuery || '',
								status_type_id: 0,
							},
							{
								onSuccess: res => {
									onSuccess(res, false, start)
								},
								onError,
							}
						)
					} else {
						activityListV2Mutate(
							{
								page_start: start,
								page_limit: 30,
								sort_flag: selectedSort.value || 0,
								flag: 0,
								queue_id: selectedQueue.queue_id,
								is_active: 0,
								is_due: 0,
								current_datetime: getCurrentDateISTWidgetDash(),
								is_unread: 0,
								is_search: !!searchQuery ? 1 : 0,
								search_string: searchQuery || '',
								status_type_id: 0,
							},
							{
								onSuccess: res => {
									onSuccess(res, false, start)
								},
								onError,
							}
						)
					}
				} else if (
					!!['MY_QUEUE', 'MENTIONS', 'DELAYED', 'UNREAD'].includes(
						selectedQueue.queue_id
					)
				) {
					let params = {}
					if (!!selectedStatus && Object.keys(selectedStatus).length > 0) {
						if (
							organization_flag_enable_tag === 1 &&
							selectedStatus.tag_id === 0
						) {
							params = {
								flag: 10,
								tag_id: selectedStatus.tag_id,
								tag_type_id: selectedStatus.tag_type_id,
								activity_status_id: selectedStatus.activity_status_id,
								activity_type_id: selectedStatus.activity_type_id,
								activity_type_category_id: 0,
							}
						} else {
							params = {
								flag: 10,
								activity_status_id: selectedStatus.activity_status_id,
								activity_type_id: selectedStatus.activity_type_id,
								activity_type_category_id:
									selectedStatus.activity_type_category_id,
							}
						}
					}
					activityListV1Mutate(
						{
							sort_flag: selectedSort.value || 0,
							flag: selectedQueue.flag || 0,
							queue_id: 0,
							is_active: !!searchQuery ? 1 : 0,
							is_due: 0,
							current_datetime: '',
							is_unread: 0,
							is_search: !!searchQuery ? 1 : 0,
							search_string: searchQuery || '',
							status_type_id: 0,
							page_start: start,
							page_limit: 30,
							...params,
						},
						{
							onSuccess: res => {
								onSuccess(res, false, start)
							},
							onError,
						}
					)
				} else {
					focusListMutate(
						{
							account_id: 1102,
							datetime_start: '1970-01-01 00:00:00',
							datetime_end: getCurrentDateISTWidgetDash(),
							flag: 2,
							flag_search: !!searchQuery ? 1 : 0,
							search_string: searchQuery || '',
							sort_flag: selectedSort.value || 0,
							page_start: start,
							page_limit: 30,
						},
						{
							onSuccess: res => {
								onSuccess(res, false, start)
							},
							onError,
						}
					)
				}
			}
		},
		[authData, locale, selectedFilter, selectedQueue, selectedStatus]
	)

	const onDebounceSearch = useCallback(
		debounce(e => {
			setdata(null)
			loadActivity({
				start: 0,
				searchQuery: e,
			})
			setSearchQuery(e)
		}, 500),
		[loadActivity]
	)

	useEffect(() => {
		if (!data && !isLoading) {
			loadActivity({
				searchQuery,
				selectedSort,
				selectedStatus,
			})
		}
	}, [data, isLoading])

	useEffect(() => {
		if (!!isReloadList) {
			setdata(null)
			dispatch({ type: sharedConst.RESET_REFRESH_LISTING })
		}
	}, [isReloadList])

	const loadChildActivities = useCallback((activity, page_start = 0) => {
		setIsLoading(page_start === 0 ? true : false)
		setIsBtnLoading(true)
		setChildActive(true)
		setChildOrderTitle(activity.activity_title)
		activityChildOrderListMutate(
			{
				parent_activity_id: activity.activity_id,
				page_start: page_start,
				page_limit: 30,
				startFrom: 0,
				sort_flag: 0,
				flag: 1,
				// flag: selectedQueue.queue_flag_show_child_workflows === 0 ? 1 : 3,
				datetime_end: getCurrentDateUTC(),
				datetime_start: '1970-01-01 00: 00: 00',
			},
			{
				onSuccess: res => {
					onSuccess(res, true, page_start)
				},
				onError,
			}
		)
	})

	const loadChildOrdersList = (activity, page_start = 0) => {
		loadChildActivities(activity, page_start)
		setActivity(activity)
	}

	return (
		<VStack spacing={2} px={2} h='full' className={`action-panel-container`}>
			{toggleButton && organization_flag_enable_collapsible === 1 && (
				<Switch
					marginLeft='95%'
					isChecked={toggleButton}
					onChange={() => {
						setToggleButton(false)
					}}
				/>
			)}
			<Button
				className={`action-panel-add-new-file-button`}
				id={`action-panel-btn-add-new-file`}
				w='100%'
				leftIcon={<Icon as={BsPlus} w={8} h={8} />}
				variant='filled'
				bg={localStorage.getItem('color')}
				color='white'
				borderRadius={6}
				fontWeight='400'
				boxShadow='md'
				textTransform='capitalize'
				onClick={() => {
					// eventLabelTrack({
					// 	categoryId: 0,
					// 	actionId: 0,
					// 	label: locale['Open View For Adding New File'],
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Home',
					// 	buttonName: 'Add New File',
					// })
					openFormView()
				}}
			>
				{locale['Add new file']}
			</Button>
			<HStack spacing={2} width='100' mx='5px'>
				<InputGroup flex={1} borderRadius='md'>
					<InputLeftElement
						children={
							<Icon
								className={`activity-listing-search-icon`}
								as={MdSearch}
								color={localStorage.getItem('color')}
								w={6}
								h={6}
							/>
						}
					/>
					<Input
						bg='white'
						placeholder={locale['Smart search']}
						borderRadius='sm'
						variant='filled'
						boxShadow='md'
						value={searchQuery}
						onChange={e => {
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Home',
							// 	buttonName: 'Smart Search',
							// })
							setSearchQuery(e.target.value)
							onDebounceSearch(e.target.value)
							setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
						}}
						onFocus={() => {
							if (panelView === ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW) {
								setSelectedQueue({
									queue_id: 'MY_QUEUE',
									queue_name: 'My Files',
									title: 'List of My files',
									isStatic: true,
								})
							}
						}}
						_focus={{
							border: 'none',
						}}
					/>
					{/* TODO: provide the option to clear the search text */}
					{searchQuery ? (
						<InputRightElement
							children={
								<Icon
									as={MdClose}
									color={localStorage.getItem('color')}
									w={6}
									h={6}
								/>
							}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FILES,
								// 	action: 'Search Query',
								// 	label: 'List Activities',
								// })
								setSearchQuery('')
								onDebounceSearch('')
								setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
							}}
						/>
					) : null}
				</InputGroup>
				<IconButton
					className={`activity-listing-refresh-icon`}
					bg='white'
					borderRadius='md'
					boxShadow='md'
					aria-label='Refresh'
					size='md'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_FILES,
						// 	action: 'Refresh',
						// 	label: 'Refresh Activities',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Home',
						// 	buttonName: 'Refresh',
						// })
						setdata(null)
						setChildActive(false)
						// setpanelView(ACTION_PANEL_VIEW.LIST_VIEW)
					}}
					icon={<Icon as={MdRefresh} w={6} h={6} />}
				/>
				<CustomMenu
					className={`activity-listing-menu-icon`}
					optionList={SortOptionList(locale)
						.map(option => {
							if ('MY_FOCUS' === selectedQueue.queue_id) {
								if (option.key !== 'last-updated-date') {
									return option
								}
							} else {
								return option
							}
						})
						.filter(Boolean)}
					selected={selectedSort}
					onSelect={item => {
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Home',
						// 	buttonName: 'Sort',
						// })
						setselectedSort(item)
						setdata(null)
						dispatch({
							type: sharedConst.SET_ACTION_PANEL_VIEW,
							view: ACTION_PANEL_VIEW.LIST_VIEW,
						})
					}}
					icon={MdSort}
				/>
			</HStack>
			{panelView === ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW ? (
				<QueueSetup
					setpanelView={setpanelView}
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
					selectedQueue={selectedQueue}
					setSelectedQueue={setSelectedQueue}
					setSelectedStatus={setSelectedStatus}
				/>
			) : panelView === ACTION_PANEL_VIEW.STATUS_TYPE_VIEW ? (
				<StatusActivityTypeView
					setpanelView={setpanelView}
					selectedFilter={selectedFilter}
					selectedQueue={selectedQueue}
					selectedStatus={selectedStatus}
					setSelectedStatus={setSelectedStatus}
					statusIndex={statusIndex}
					setStatusIndex={setStatusIndex}
				/>
			) : (
				<ActivityListView
					openActivityView={openActivityView}
					setpanelView={setpanelView}
					selectedFilter={selectedFilter}
					selectedQueue={selectedQueue}
					selectedStatus={selectedStatus}
					setStatusIndex={setStatusIndex}
					data={data}
					loadChildOrdersList={loadChildOrdersList}
					loadActivity={loadActivity}
					setdata={setdata}
					isChildActive={isChildActive}
					setChildActive={setChildActive}
					activity={activity}
					child_title={child_title}
					isLoading={isLoading}
					isBtnLoading={isBtnLoading}
					selectedSort={selectedSort}
					searchQuery={searchQuery}
				/>
			)}
		</VStack>
	)
}

export default ActionPanel
