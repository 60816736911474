import { Button, Flex, HStack, VStack, Icon, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiArrowLeftCircle } from 'react-icons/fi'
import ActivityList from '../../../components/activity-list'
import { ACTION_PANEL_VIEW } from '../action-panel'
import { SortOptionList } from './sort.option'
import { toSnakeCase } from '../../../utils/common.util'
import { useAuthContext } from '../../../context/auth.context'
// import {
//   sectionDetailedTrack,
//   dataLayerTagManager,
// } from '../../repository/repo.utils'
import { GA_CATEGORY_FILES } from './../../repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { SmartFormContextProvider } from '../../../context/smart-form.context'
const ActivityListView = ({
	setpanelView,
	selectedFilter,
	selectedQueue,
	selectedStatus,
	openActivityView,
	setStatusIndex,
	data,
	loadChildOrdersList,
	loadActivity,
	setdata,
	setChildActive,
	isChildActive,
	child_title,
	activity,
	isBtnLoading,
	isLoading,
	selectedSort,
	searchQuery = '',
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { organization_flag_enable_tag } = authData
	return (
		<>
			<HStack
				w='100%'
				bg='secondary'
				spacing={2}
				p={2}
				className={`activity-listing-container`}
			>
				<Icon
					className={`activity-listing-back-icon`}
					id={`activity-listing-back-icon ${selectedQueue.queue_id}`}
					as={FiArrowLeftCircle}
					cursor='pointer'
					color={localStorage.getItem('color')}
					w={6}
					h={6}
					onClick={() => {
						// sectionDetailedTrack({
						//   category: GA_CATEGORY_FILES,
						//   action: 'Activity List View',
						//   label: 'Go Back',
						// })
						if (selectedQueue.queue_id === 'MY_QUEUE') {
							setStatusIndex({
								cat: selectedStatus.cat_index,
								sub_cat: selectedStatus.sub_cat_index,
							})
							setpanelView(ACTION_PANEL_VIEW.STATUS_TYPE_VIEW)
						} else {
							setStatusIndex({
								cat: selectedStatus.cat_index,
								sub_cat: selectedStatus.sub_cat_index,
							})
							setpanelView(ACTION_PANEL_VIEW.QUEUE_SETUP_VIEW)
						}
					}}
				/>
				{!selectedQueue.title || !!Object.keys(selectedStatus).length ? (
					<VStack>
						<Text>
							List of{' '}
							{!!selectedStatus.hideCategory ? (
								<Text as='span' fontWeight='900'>
									{organization_flag_enable_tag === 1
										? selectedStatus.tag_type_name
											? selectedStatus.tag_type_name.endsWith('y')
												? selectedStatus.tag_type_name.slice(0, -1) + 'ies'
												: selectedStatus.tag_type_name + 's'
											: locale['Others']
										: selectedStatus.activity_type_category_name + 's'}
								</Text>
							) : (
								<Text as='span' fontWeight='900'>
									{selectedStatus.activity_type_name ||
										selectedQueue.queue_name ||
										selectedFilter.activity_type_name + ` files`}
								</Text>
							)}
						</Text>
						{!selectedStatus.hideStatus &&
						!!selectedStatus.activity_status_name ? (
							<Text>
								{locale['Selected status']}{' '}
								<Text as='span' fontWeight='900'>
									{selectedStatus.activity_status_name}
								</Text>
							</Text>
						) : null}
					</VStack>
				) : (
					<Text fontWeight='500'>{selectedQueue.title}</Text>
				)}
			</HStack>

			{isChildActive && (
				<HStack w='100%' bg='secondary' spacing={2} p={2}>
					<Icon
						className={`activity-listing-back-icon-${toSnakeCase(child_title)}`}
						as={FiArrowLeftCircle}
						cursor='pointer'
						color={localStorage.getItem('color')}
						w={6}
						h={6}
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_FILES,
							//   action: 'Activity List View',
							//   label: 'Go Back Active Child',
							// })
							setdata(null)
							setChildActive(false)
						}}
					/>
					<VStack>
						<Text>
							{' '}
							<Text as='span' fontWeight='900'>
								{child_title}
							</Text>{' '}
						</Text>
					</VStack>
				</HStack>
			)}
			<SmartFormContextProvider>
				<ActivityList
					openActivityView={openActivityView}
					isLoading={isLoading}
					data={!!data && !!data.list ? data.list : []}
					selectedQueue={selectedQueue}
					selectedFilter={selectedFilter}
					loadChildOrders={loadChildOrdersList}
					searchQuery={searchQuery}
				/>
			</SmartFormContextProvider>
			{data && !!data.isMore ? (
				<Flex>
					<Button
						borderRadius='full'
						size='xs'
						px={2}
						variant='outline'
						color='brand.800'
						isLoading={isBtnLoading}
						loadingText='Loading'
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_FILES,
							//   action: 'Activity List View',
							//   label: 'Load More',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Workflow List',
							//   buttonName: 'Load More',
							// })
							isChildActive
								? loadChildOrdersList(activity, data.page_start)
								: loadActivity({
										selectedSort,
										selectedStatus,
										start: data.page_start,
								  })
						}}
					>
						{locale['Load more']}
					</Button>
				</Flex>
			) : null}
		</>
	)
}

export default ActivityListView
